import woocommerce from '../components/woocommerce';
// import $events from '../core/events/events';
// import { _body } from '../core/variables';

export default {
  init() {
    // JavaScript to be fired on the about us page
    woocommerce();

    // $events.on(
    //   {
    //     '.js-save-account': {
    //       click(e) {
    //         // Click logic
    //         e.preventDefault();
    //         var save_nonce = $('#save-account-details-nonce').attr('value');
    //         var wp_nonce = $('#_wpnonce').attr('name');
    //         var nonce_value = $('[value=save_account_details]').val();
    //         var first_name = $('#account_first_name').val();
    //         var last_name = $('#account_last_name').val();

    //         $.ajax({
    //           url: woocommerce_params.ajax_url,
    //           type: 'POST',
    //           data: {
    //             action: 'woo_save_account_details',
    //             save_nonce_value: save_nonce,
    //             wp_nonce: wp_nonce,
    //             nonce_value: nonce_value,
    //             account_first_name: first_name,
    //             account_last_name: last_name,
    //           },
    //           success: function (response) {
    //             $(_body).append(response);
    //           },
    //         });
    //       },
    //     },
    //   },
    //   {
    //     once: true,
    //   }
    // );
  },
};
