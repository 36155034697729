import { _body } from '../core/variables';
import { fixed } from '../classes/classes';


export default class NavMenu {
  constructor(element) {
    element.addEventListener('click', () => {
        // Toggle class "is-active"
        const header = document.querySelector('.header');
        element.classList.toggle('is-active');
        header.classList.toggle('hamburger-open');
        _body.classList.toggle(fixed);
    })
  }
}
