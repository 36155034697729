import passwordStrength from '../components/password-strength-meter';
import woocommerce from '../components/woocommerce';

export default {
  init() {
    // $(function () {
    //   $('#login-tabs').tabs();
    // });
    // JavaScript to be fired on all pages
  },
  finalize() {
    woocommerce();
    passwordStrength();

    window.addEventListener('load', function () {
      var b = document.querySelector('.ui-switcher');
      b.style.setProperty('touch-action', 'pan-y pinch-zoom');

      // store tabs variable
      var myTabs = document.querySelectorAll('ul#login-tabs > li');

      function myTabClicks(tabClickEvent) {
        for (var i = 0; i < myTabs.length; i++) {
          myTabs[i].classList.remove('ui-active');
        }

        var clickedTab = tabClickEvent.currentTarget;

        clickedTab.classList.add('ui-active');

        tabClickEvent.preventDefault();

        var myContentPanes = document.querySelectorAll('.tab-pane');

        for (i = 0; i < myContentPanes.length; i++) {
          myContentPanes[i].classList.remove('ui-active');
        }

        var anchorReference = tabClickEvent.target;
        var activePaneId = anchorReference.getAttribute('href');
        console.log(activePaneId);
        var activePane = document.querySelector(activePaneId);

        activePane.classList.add('ui-active');
      }

      /* eslint-disable */
      for (i = 0; i < myTabs.length; i++) {
        myTabs[i].addEventListener('click', myTabClicks);
      }
      /* eslint-enable */
    });
  },
};
