import { _win } from '../core/variables';
import $events from '../core/events/events';
import { shown } from '../classes/classes';

export default {
  init() {
    $events.on({
      '.js-account': {
        mouseenter: function () {
          this.classList.add(shown);
        },

        mouseleave: function () {
          this.classList.remove(shown);
        },
      },
    });

    function handleScroll() {
      $('.js-account').removeClass(shown);
    }

    $events.on(_win, 'scroll', handleScroll);
  },
  finalize() {},
};
