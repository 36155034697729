import $events from '../core/events/events';
import UIkit from 'uikit';

export default {
  init() {
    $events.on({
      '.js-modal-trigger': {
        click() {
          var modals = document.getElementById('event-info');
          var hey = this.closest('.playbill__list');
          var hoy = hey.querySelectorAll('.playbill__list-title')[0].getAttribute('title');
          var linkimage = hey.querySelectorAll('.attachment-small')[0].getAttribute('data-src');
          var link = hey.querySelectorAll('.list-post-link')[0].getAttribute('href');
          var venue = hey.querySelectorAll('.playbill__list-venue')[0].innerText;

          var main = {
            div: 'div',
            section: 'section',
            a: 'a',
            button: 'button',
            img: 'img',
            svg: 'svg',
          };

          var popContainer = document.createElement(main.div);
          popContainer.classList = 'uk-modal-dialog uk-position-fixed uk-position-bottom uk-position-z-index uk-animation-slide-bottom uk-card uk-card-default uk-card-body uk-padding-remove uk-width';
          popContainer.setAttribute('data-modal', 'info');
          modals.appendChild(popContainer);

          var popHeader = document.createElement(main.button);
          popHeader.classList = 'uk-modal-close-default';
          popHeader.setAttribute('uk-close', '');
          popContainer.append(popHeader);

          // Section Line up
          var section_lineup = document.createElement(main.section);
          section_lineup.classList = 'YoDOb event-list-panel-item--lineupImage';
          popContainer.append(section_lineup);

          var lineup_1_content = document.createElement(main.div);
          lineup_1_content.classList = 'crbKxn izUOEw';
          lineup_1_content.innerText = 'Lineup';
          section_lineup.append(lineup_1_content);

          var lineup_2_wrapped = document.createElement(main.div);
          lineup_2_wrapped.classList = 'hvjSQK';
          section_lineup.append(lineup_2_wrapped);

          var lineup_2_content = document.createElement(main.div);
          lineup_2_content.classList = 'kqrCfE event__list__link--modal';
          lineup_2_wrapped.append(lineup_2_content);

          var lineup_inside_1 = document.createElement(main.div);
          lineup_inside_1.classList = 'kIYIWS';
          lineup_2_content.append(lineup_inside_1);

          var lineup_inside_1_content = document.createElement(main.div);
          lineup_inside_1_content.style.width = '65px';
          lineup_inside_1_content.style.height = '65px';
          lineup_inside_1_content.style.borderRadius = '50%';
          lineup_inside_1_content.style.overflow = 'hidden';
          lineup_inside_1.append(lineup_inside_1_content);

          var lineup_cont_image = document.createElement(main.img);
          lineup_cont_image.src = linkimage;
          lineup_inside_1_content.append(lineup_cont_image);

          var lineup_inside_2 = document.createElement(main.div);
          lineup_inside_2.classList = 'hTRmfd';
          lineup_2_content.append(lineup_inside_2);

          var lineup_inside_2_content = document.createElement(main.a);
          lineup_inside_2_content.classList = 'uk-text-primary uk-text-meta iRxubR';
          lineup_inside_2_content.innerText = hoy;
          lineup_inside_2_content.href = link;
          lineup_inside_2.append(lineup_inside_2_content);

          // Section Venue
          var section_venue = document.createElement(main.section);
          section_venue.classList = 'YoDOb';
          popContainer.append(section_venue);

          var venue_1_content = document.createElement(main.div);
          venue_1_content.classList = 'uk-text-meta uk-text-uppercase izUOEw';
          venue_1_content.innerText = 'Venue Info';
          section_venue.append(venue_1_content);

          var venue_2_content = document.createElement(main.div);
          venue_2_content.classList = 'kVDZYG';
          section_venue.append(venue_2_content);

          var venue_svg_content = document.createElement(main.div);
          venue_svg_content.classList = 'kqrCfE';
          venue_2_content.append(venue_svg_content);

          var venue_titles = document.createElement(main.div);
          venue_titles.classList = 'uk-text-primary hTRmfd';
          venue_2_content.append(venue_titles);

          var venue_link = document.createElement(main.a);
          venue_link.classList = ' uk-link-reset iRxubR';
          venue_link.innerText = venue;
          venue_titles.append(venue_link);

          var venue_the_svg_content = document.createElement(main.svg);
          venue_svg_content.append(venue_the_svg_content);

          // Footer

          var footer = document.createElement(main.div);
          footer.classList = 'hNnNUE';
          popContainer.append(footer);

          var footer_button = document.createElement(main.a);
          footer_button.classList = 'uk-button uk-button-primary uk-text-bolder uk-width-1-1';
          footer_button.innerText = 'Ver Obra';
          footer_button.href = link;
          footer.append(footer_button);
          UIkit.modal('#event-info').show();
        },
      },
    });

    UIkit.util.on('#event-info', 'hidden', function () {
      $('.uk-modal-dialog[data-modal="info"]').remove();
    });
  },
  finalize() {
    // eslint-disable-next-line
  },
};
