export default class preLoader {
  constructor(element, options) {
    setTimeout(() => {
      element.classList.add('hide');
      setTimeout(() => {
        element.style.display = 'none';
      }, 150);
    }, options.duration);
  }
}
