export default {
  init() {
    var viewed_products_arr = JSON.parse(localStorage.getItem('recently_viewed'));
    if (viewed_products_arr != null && viewed_products_arr != '')
      // var viewed_products_number = viewed_products_arr.length;
      // if (viewed_products_number > 4) {
      //   //if more than 5 products viewed only keep the last 6
      //   var viewed_products = viewed_products_arr.slice(viewed_products_number - 6, viewed_products_number);
      // } else {
      //   //otherwise keep them all
      //   var viewed_products = viewed_products_arr;
      // }

      // const recentlyViewed = {
      //   container: {
      //     class: 'xs',
      //   },
      // }

      var hero_home = document.getElementById('hero_home');
    var ticketsViewsSection = document.createElement('section');
    ticketsViewsSection.setAttribute('id', 'recently_viewed');
    ticketsViewsSection.classList = 'uk-container uk-container-expand uk-section';
    hero_home.after(ticketsViewsSection);

    var recentlyViewTitle = document.createElement('h2');
    recentlyViewTitle.innerText = 'Recientemente visto';
    recentlyViewTitle.classList = 'uk-h3 uk-light uk-text-bold';
    ticketsViewsSection.append(recentlyViewTitle);

    var ticketsGrid = document.createElement('div');
    ticketsGrid.classList = 'uk-grid uk-grid-small uk-child-width-1-2@s uk-child-width-1-3@m uk-child-width-1-4@l';
    ticketsGrid.setAttribute('uk-grid', '');
    ticketsViewsSection.append(ticketsGrid);

    var placeholder = {
      card: {
        main: 'uk-flex uk-flex-middle uk-padding-xsmall uk-padding-remove-vertical uk-border-pill uk-background-secondary faAGPG uk-box-shadow-hover-large uk-cursor-pointer uk-light',
        imgContainer: 'uk-background-cover uk-margin-small-right uk-border-circle uk-border',
      },
    };

    $.each(viewed_products_arr, function (index) {
      //product data
      var name = this.name;
      var imgUrl = this.imageUrl;
      var url = this.url;

      var container = document.createElement('div');
      container.setAttribute('data-index', index);
      ticketsGrid.append(container);

      var card = document.createElement('div');
      card.classList = placeholder.card.main;
      card.style.setProperty('height', '64px');
      container.append(card);

      var imageContainer = document.createElement('div');
      imageContainer.classList = placeholder.card.imgContainer;
      imageContainer.setAttribute('data-src', imgUrl);
      imageContainer.setAttribute('width', '48');
      imageContainer.setAttribute('height', '48');
      imageContainer.setAttribute('uk-img', '');
      card.append(imageContainer);

      var imageUrl = document.createElement('canvas');
      imageUrl.setAttribute('width', '48');
      imageUrl.setAttribute('height', '48');
      imageContainer.append(imageUrl);

      var nameContainer = document.createElement('div');
      nameContainer.classList = 'uk-text-truncate uk-text-meta';
      card.append(nameContainer);

      var close = document.createElement('button');
      close.classList = 'uk-margin-auto-left uk-margin-small-right uk-icon-button';
      close.setAttribute('type', 'button');
      close.setAttribute('uk-icon', 'close');
      card.append(close);

      var nameUrl = document.createElement('a');
      nameUrl.href = url;
      nameUrl.innerText = name;
      nameContainer.append(nameUrl);
    });

    $('[uk-icon="close"]').click(function (event) {
      var index = $(event.target).closest('[data-index]').attr('data-index');
      viewed_products_arr.splice(index, 1);
      localStorage.setItem('recently_viewed', JSON.stringify(viewed_products_arr));
      if (viewed_products_arr.length == 0) {
        $(ticketsViewsSection).remove();
        $(event.target).closest('[data-index]').remove();
      }
      $(event.target).closest('[data-index]').remove();
    });
  },
  finalize() {},
};
