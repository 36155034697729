export default {
  init() {
    var productJson = JSON.parse(document.querySelector('[data-json]').innerHTML);
    var storedProducts = JSON.parse(localStorage.getItem('recently_viewed'));

    function addRecentlyViewed() {
      if (storedProducts === null) storedProducts = [];
      var currentEventTicketsID = productJson.id;
      var currentEventTicketsName = productJson.name;
      var currentEventTicketsUrl = productJson.url;
      var currentEventTicketsImageUrl = productJson.imageUrl;
      var currentEventTicketsVenueName = productJson.venueName;
      var currentEventTicketsCategory = productJson.category;
      var product = {
        id: currentEventTicketsID,
        name: currentEventTicketsName,
        url: currentEventTicketsUrl,
        imageUrl: currentEventTicketsImageUrl,
        venueName: currentEventTicketsVenueName,
        category: currentEventTicketsCategory,
      };
      if (localStorage.getItem('recently_viewed') === null) {
        var a = [];
        a.push(product);
        localStorage.setItem('recently_viewed', JSON.stringify(a));
      } else {
        //recent product views exist
        //re-construct array from local storage data
        // eslint-disable-next-line
        var a = [];
        a = JSON.parse(localStorage.getItem('recently_viewed'));

        //check page product id against ids of previous viewed products
        //if there's a match, remove any previous view of this product from the array
        var findById = function (source, id) {
          for (var i = source.length - 1; i >= 0; i--) {
            if (source[i].id === id) {
              source.splice(i, 1);
            }
          }
        };
        findById(a, product.id);

        //push new product view to array
        //this ensures each product in the array is unique andthe most recent view is always at the end
        a.push(product);
        localStorage.setItem('recently_viewed', JSON.stringify(a));
      }
    }
    addRecentlyViewed();
  },
};
