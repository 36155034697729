export default [
    'transitionstart',
    'transitioncancel',
    'transitionend',
    'transitionrun',
    'animationstart',
    'animationend',
    'animationiteration',
    'beforeprint',
    'afterprint',
];