/* global single_event_params */
import $events from '../core/events/events';
import { _body } from '../core/variables';
import UIkit from 'uikit';

function handleScroll() {
  $events.on('.js-info', {
    click() {
      UIkit.modal('#event-about').show();
    },
  });
}

/**
 * Bind events
 */
function bindEvents() {
  $events.on(_body, 'click', handleScroll);

  $events.on(
    {
      '.js-info': {
        click(e) {
          if (typeof single_event_params === 'undefined') {
            return false;
          }
          e.preventDefault();

          let id = $('.js-info').attr('data-id');
          
          $.ajax({
            url: single_event_params.ajax_url,
            type: 'POST',
            data: {
              action: 'dcms_ajax_readmore',
              id_post: id,
            },
            success: function (response) {
              $('#event-about').append(response);
              UIkit.modal('#event-about').show();
            },
          });
        },
      },
    },
    {
      once: true,
    }
  );
}

export default function panels() {
  bindEvents();
}
