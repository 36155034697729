// import external dependencies
import 'jquery';
import 'select2';
import 'select-woo';
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';

// Import everything from autoload
;

// import local dependencies
import Router from './util/Router';
import common from './routes/common';
import home from './routes/home';
import acerca from './routes/acerca';
import archivePlaybill from './routes/archive-playbill';
import archiveTickets from './routes/archive-tickets';
import singleEvent from './routes/single-event';
import search from './routes/search';
import cart from './routes/cart';
import checkout from './routes/checkout';
import login from './routes/login';
import loggedIn from './routes/logged-in';
import account from './routes/account';
import preLoader from './components/preloader';
import SideMenu from './components/sidemenu';
import NavMenu from './components/navigation-mobile';
import singleTickets from './routes/single-tickets';
//import playbill from './routes/playbill';

/** Populate Router instance with DOM routes */
const routes = new Router({
  // All pagesn
  common,
  // Home page
  home,
  // About Us page, note the change from about-us to aboutUs.
  acerca,
  //playbill,
  archivePlaybill,
  archiveTickets,
  singleTickets,
  singleEvent,
  login,
  search,
  cart,
  checkout,
  account,
  loggedIn,
});

// loads the Icon plugin
UIkit.use(Icons);

// Load Events
jQuery(document).ready(() => routes.loadEvents());

const components = [
  {
    class: SideMenu,
    selector: ['.js-modal-trigger'],
  },
  {
    class: NavMenu,
    selector: '.hamburger',
  },
  {
    class: preLoader,
    selector: '.preloader',
    options: {
      duration: 1500,
    },
  },
];

components.forEach((component) => {
  if (document.querySelector(component.selector) !== null) document.querySelectorAll(component.selector).forEach((element) => new component.class(element, component.options));
});
