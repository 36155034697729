import tickets_blocks from '../components/tickets-blocks';
import panels from '../components/panel';
// import $events from '../core/events/events';

export default {
  init() {},
  finalize() {
    // JavaScript to be fired on the home page, after the init JS
    /* eslint-disable */

    // $('main#tribe-events').attr('uk-height-viewport','offset-top: true');
    tickets_blocks();
    panels();
    /* eslint-enable */
  },
};
