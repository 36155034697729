export const active = 'is-active';
export const sticky = 'is-sticky';
export const shown = 'is-shown';
export const hidden = 'is-hidden';
export const fixed = 'is-fixed';

export default {
    active,
    sticky,
    shown,
    hidden,
    fixed,
};